import {
	apiActivateStudent,
	apiVerifyStudentActivation,
	apiResetStudentPassword,
	apiVerifyStudentPasswordReset,
	IStudent
} from "@digitale-lernwelten/ugm-client-lib";
import { showModal } from "../../modal/modal";
import { hideOverlay } from "../../overlay/overlay";
import configuration from "../../../configuration";
import { ugmLogin } from "../user-group-management";

import "./user-activation.css";
import { showRegisterModal } from "../session-management/login";

type TLinkType = 'activation' | 'password'

const validatePassword = (pw:string):boolean => pw.length >= 8;

const showErrorModal = () => {
	const modal = showModal(`
	<div class="login-modal">
		<h3>Fehler bei der Benutzeraktivierung</h3>
		<br/>
		<p>Leider ist ein Fehler bei der Nutzeraktivierung aufgetreten, bitte &uuml;berpr&uuml;fen Sie den Link und probieren es sp&auml;ter erneut.</p>
	</div>`,
	"centered-box");
	const button = modal.querySelector<HTMLInputElement>(`input[type="submit"]`);
	if(!button){return;}
	button.onclick = hideOverlay;
};

const showSuccesModal = (username:string) => {
	const modal = showModal(`
	<div class="success-modal">
		<h3>Deine Kennung</h3>
		<h5>${username}</h5>
		<br/>
		<p>Bitte schreib dir deine Kennung auf und merke dir dein Passwort gut! Solltest du dein Passwort vergessen, kann es <b>deine Lehrkraft</b> für dich zurücksetzen.</p>
		<input type="submit" value="Los geht's!"/>
	</div>`,
	"centered-box");
	const button = modal.querySelector<HTMLInputElement>(`input[type="submit"]`);
	if(!button){return;}
	button.onclick = hideOverlay;
};

const verifyToken = async(token: string, linkType: TLinkType): Promise<boolean> => {
	if (linkType === 'activation') {
		return apiVerifyStudentActivation(token);
	} else {
		return apiVerifyStudentPasswordReset(token);
	}
};

const initUserActivationModal = async (token: string, linkType: TLinkType) => {
	try {
		const tokenValidation = await verifyToken(token, linkType);
		if(!tokenValidation){
			showErrorModal();
			return;
		}
	} catch {
		showErrorModal();
		return;
	}
	const modal = showModal(`
	<div class="login-modal">
		<form>
			<h3>${ linkType === 'activation' ? "Aktiviere deinen Zugang für" : "Passwort zurücksetzen für"}</h3>
			<h4>${window.location.hostname}</h4>
			<label>
				<span>Passwort</span>
				<input tabindex="1" type="password" name="password" value="" placeholder="Passwort eingeben" autocomplete="off"/>
				<span class="errors"></span>
				<div class="small-hint">Das Passwort muss mindestens 8 Zeichen lang sein.</div>
			</label>
			<label>
				<span class="error-pw-response">Passwort wiederholen</span>
				<input tabindex="2" type="password" name="repeat-password" value="" placeholder="Passwort wiederholen" autocomplete="off"/>
				<span class="repeat-errors"></span>
			</label>
			<label class="check-container">
				Ich habe die <a href="/inhalt/datenschutz" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiert.
				<input type="checkbox" name="check-agree"/>
				<span class="checkmark"></span>
			</label>
			<input type="submit" class="inactive" value="Los geht's!"/>
		</form>
	</div>`,
	"centered-box");
	const button = modal.querySelector<HTMLInputElement>(`input[type="submit"]`);
	const password = modal.querySelector<HTMLInputElement>(`input[type="password"]`);
	const errormsg = modal.querySelector<HTMLElement>(".errors");
	const passwordRepeat = modal.querySelector<HTMLInputElement>(`input[name="repeat-password"]`);
	const errormsgRepeat = modal.querySelector<HTMLElement>(".repeat-errors");
	const errorEleResponse = modal.querySelector<HTMLElement>(".error-pw-response");
	const checkAgb = modal.querySelector<HTMLInputElement>(`[name="check-agree"]`);
	if (!button || !password || !errormsg || !passwordRepeat || !errormsgRepeat || !errorEleResponse || !checkAgb ) { return;}
	password.focus();

	const validateInput = () => {
		if (validatePassword(password.value)) {
			errormsg.innerText = "";
		} else {
			errormsg.innerText = "Das Passwort ist zu kurz";
			button.classList.add("inactive");
		}

		if (password.value && (password.value === passwordRepeat.value)) {
			errormsgRepeat.innerText = "";
			errorEleResponse.classList.add("repeat-ok");
		} else {
			errormsgRepeat.innerText = "Die Passwörter stimmt nicht überein";
			button.classList.add("inactive");
			errorEleResponse.classList.remove("repeat-ok");
		}

		checkAgb.dispatchEvent(new Event("change"));
	};

	const validateInputOnChange = () => {
		if (validatePassword(password.value)) {
			errormsg.innerText = "";
		}

		if (password.value && (password.value === passwordRepeat.value)) {
			errormsgRepeat.innerText = "";
			errorEleResponse.classList.add("repeat-ok");
		} else {
			button.classList.add("inactive");
			errorEleResponse.classList.remove("repeat-ok");
		}
		checkAgb.dispatchEvent(new Event("change"));
	};

	password.oninput = validateInputOnChange;
	passwordRepeat.oninput = validateInputOnChange;

	checkAgb.addEventListener("change", ()=>{
		if (password.value === passwordRepeat.value
			&& validatePassword(password.value)
			&& checkAgb.checked) {
			button.classList.remove("inactive");
		} else {
			button.classList.add("inactive");
		}
	});
	button.onclick = async e => {
		e.preventDefault();
		validateInput();
		if(button.classList.contains("inactive")|| !checkAgb.checked || password.value !== passwordRepeat.value){return;}
		if (!validatePassword(password.value)){return;}
		try {
			password.readOnly = true;
			button.classList.add("inactive"); // This is to make sure that we don't double submit
			let currentUser: IStudent;
			if (linkType === 'activation') {
				currentUser = await apiActivateStudent(token, password.value);
			} else {
				currentUser = await apiResetStudentPassword(token, password.value);
			}
			if(currentUser){
				errormsg.innerText = '';
				await ugmLogin(currentUser.loginName, password.value);
				hideOverlay();
				const newUrl = window.location.href.substring(0,window.location.href.indexOf("?"));
				window.history.replaceState(null, "", newUrl);
				showSuccesModal(currentUser.loginName);
			}else{
				errormsg.innerText = "Aktivierung fehlgeschlagen, bitte prüfen Sie Ihre Eingabe und versuchen Sie es erneut.<br/><br/>";
			}
		}catch(e) {
			password.readOnly = false;
			button.classList.remove("inactive");
			errormsg.innerHTML = "Aktivierung fehlgeschlagen, bitte prüfen Sie Ihre Eingabe und versuchen Sie es erneut.<br/><br/>";
		}
	};
};

const openInstitutionTeacherRegistrationModla = (institutionCode:string) => {
	showRegisterModal(false, institutionCode);
};

const initUGMUserActivation = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const institutionCode = urlParams.get("create-institution-teacher");
	if(institutionCode){
		openInstitutionTeacherRegistrationModla(institutionCode);
		return;
	}
	const token = urlParams.get("ugm-activate") || urlParams.get("ugm-pw-reset");
	let linkType: TLinkType | null = null;
	const act = urlParams.get("ugm-activate");
	const pw = urlParams.get("ugm-pw-reset");
	if (act) {
		linkType = 'activation';
	} else if (pw) {
		linkType = 'password';
	}

	if (token && linkType){
		initUserActivationModal(token, linkType);
	}
};

if(configuration.ugmEndpoint){
	setTimeout(initUGMUserActivation,0);
}
