import {customElement} from "lit/decorators.js";
import {css, html, LitElement} from "lit";
import {property, query} from "lit/decorators.js";
import {apiCreateTeacher} from "@digitale-lernwelten/ugm-client-lib";
import {showModal} from "../../modal/modal";

@customElement("netbook-shop-form")
export class NetbookShopForm extends LitElement {
	static styles = css`
		* {
			box-sizing: border-box;
		}

		form {
			width: 100%;
			max-width: var(--content-width);
			margin: 0 auto 0;
			padding: 1rem var(--content-padding) 2rem;
		}

		input[data-valid="false"] {
			border: 4px solid #E3000F;
		}

		p.big {
			font-family: "Livvic", sans-serif;
			font-size: 1.5rem;
			font-weight: lighter;
			line-height: 1.5em;
			font-style: normal;
		}

		form {
			display: flex;
			flex-flow: column nowrap;
		}

		#error-message {
			font-family: "Livvic", sans-serif;
			font-size: 1.5rem;

			color: #E3000F;
			background-color: #ffe5e7;

			padding: 2rem;
			margin-inline: auto;
			margin-block: 1rem;

			border-left: 5px solid #E3000F;
		}

		#error-message.hide {
			display: none;
		}

		input[type="text"],
		input[type="email"],
		input[type="password"] {
			font-family: "Livvic", sans-serif;
			font-size: 1.5rem;
			padding: 1rem 1.5rem;
			border: none;
			background-color: #FFFFFF;
			margin-inline: auto;
			outline: none;
			width: 100%;
			margin-bottom: 2rem;
		}

		input[type="submit"] {
			font-family: "Livvic", sans-serif;
			font-size: 1.75rem;
			color: white;
			width: fit-content;
			margin: 0 auto;
			padding: 0.5rem 4rem;
			border: none;
			background-color: #26c88b;
			border-radius: 2px;
			box-shadow: 0 3px 6px #00000029;
			cursor: pointer;
			text-decoration: none;
			white-space: normal;
			line-height: 1.4em;
			transition: background-color 200ms;
			width: 100%;
			max-width: fit-content;
		}

		@media screen and (max-width: 900px) {
			input[type="submit"],
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1.5rem;
			}
		}

		@media screen and (max-width: 700px) {
			input[type="submit"],
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1.35rem;
			}
		}

		@media screen and (max-width: 500px) {
			input[type="submit"],
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1.2rem;
			}

			input[type="submit"] {
				padding: 0.5rem 2rem;
			}
		}

		form p {
			font-style: italic;
			font-size: 1rem;
			color: white;
			line-height: 1.3em;
			margin-top: 0.5rem;
			text-align: center;
		}
	`;

	@property()
		email = '';

	@property()
		loginName = '';

	@property({type: Boolean})
		validEmail = true;

	@property({type: Boolean})
		validLoginName = true;

	@property()
		redirectTarget = window.location.origin;

	@query('input[id="email"]')
		_email: HTMLInputElement;

	@query('input[id="username"]')
		_username: HTMLInputElement;

	@query('#error-message')
		_error: HTMLSpanElement;

	_handleInput() {
		this.email = this._email.value;
		this.loginName = this._username.value;
	}

	async _registerUser(e: Event) {
		e.preventDefault();

		if(!this.email || !this.email.includes("@")) {
			this.validEmail = false;
			this._error.innerHTML = "Alle Felder müssen ausgefüllt werden";
			this._error.classList.remove("hide");
			return;
		}

		if(!this.loginName) {
			this.validLoginName = false;
			this._error.innerHTML = "Alle Felder müssen ausgefüllt werden";
			this._error.classList.remove("hide");
			return;
		}

		try {
			await apiCreateTeacher(this.email, this.loginName);

			showModal(`<h1>Vielen Dank für Ihre Registrierung!</h1><p>Wir haben eine E-Mail an <strong>${this.email}</strong> geschickt.<br/>Klicken Sie auf den Link in der E-Mail, um die Registrierung abzuschließen.</p>`, "centered-box");

		} catch (err) {
			console.error(err);
			let errorMessage = "";
			switch (err.errorCode) {
			case "emailInUse":
				errorMessage = "Die E-Mail-Adresse ist bereits vergeben.";
				break;
			case "loginNameInUse":
				errorMessage = "Das Kürzel ist bereits vergeben.";
				break;
			case "teacherAlreadyExists":
				errorMessage = "Die Lehrkraft existiert bereits.";
				break;
			default:
				errorMessage = "Die Registrierung war nicht erfolgreich, bitte versuchen Sie es erneut.";
			}
			this._error.innerHTML = errorMessage;
			this._error.classList.remove("hide");
		}
	}
	render() {
		return html`
			<div>
				<form @submit="${this._registerUser}">
					<p class="big">Um alle adaptiven und kommunikativen Funktionen (siehe Video unten)<br/>kostenlos zu testen, k&ouml;nnen Sie sich hier unverbindlich registrieren.</p>
					<input id="email" type="email" placeholder="E-Mail Adresse" value=${this.email} @input=${this._handleInput} data-valid=${this.validEmail}/>
					<input id="username" type="text" placeholder="Ihr selbst gewähltes Kürzel" value=${this.loginName} @input=${this._handleInput} data-valid=${this.validLoginName}/>
					<input type="submit" value="30 Tage kostenlos testen"/>
					<span id="error-message" class="hide"></span>
				</form>
			</div>
		`;
	}
}
