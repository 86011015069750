import {customElement, property, query} from "lit/decorators.js";
import {css, html, LitElement} from "lit";
import {apiCreateTeacher} from "@digitale-lernwelten/ugm-client-lib";
import {showModal} from "../../modal/modal";

@customElement("hamburg-register")
export class HamburgRegister extends LitElement {
	static styles = css`
		input[type="text"],
		input[type="email"],
		input[type="password"] {
			font-family: "Livvic", sans-serif;
			font-size: 1.5rem;
			padding: 1rem 1.5rem;
			border: none;
			background-color: #FFFFFF;
			margin-top: 32px;
			width: 35%;
			margin-inline: auto;
		}

		input::placeholder {
			color: #2AB0D799;
		}

		input[type="submit"] {
			font-family: "Livvic", sans-serif;
			font-size: 2rem;
			color: white;
			width: fit-content;
			margin-inline: auto;
			padding: 0.5rem 4rem;
			border: none;
			background-color: #E3000F;
			margin-top: 48px;
			cursor: pointer;
		}

		input[type="submit"]:hover {
			background-color: #a0000a;
		}

		input[type="submit"]:active {
			background-color: #ff2938;
		}

		input[data-valid="false"] {
			border: 4px solid #E3000F;
		}

		form {
			display: flex;
			flex-flow: column nowrap;
		}

		#error-message {
			font-family: "Livvic", sans-serif;
			font-size: 1.5rem;

			color: #E3000F;
			background-color: #ffe5e7;

			padding: 2rem;
			margin-inline: auto;
			margin-block: 1rem;

			border-left: 5px solid #E3000F;
		}

		#error-message.hide {
			display: none;
		}

		form p {
			color: #FFFFFF;
			width: 100%;
			max-width: 600px;
			padding:1rem 2rem 0;
			margin:0 auto;
		}

		@media screen and (max-width: 900px) {
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1.5rem;
				width: 50%;
			}

			input[type="submit"] {
				font-size: 1.5rem;
				padding: 0.5rem 4rem;
				margin-top: 48px;
			}
		}

		@media screen and (max-width: 500px) {
			input[type="text"],
			input[type="email"],
			input[type="password"] {
				font-size: 1rem;
				width: 75%;
			}
		}
	`;

	@property()
		email = '';

	@property()
		validEmail = true;

	@property()
		validLoginName = true;

	@property()
		loginName = '';

	@property()
		redirectTarget = window.location.origin;

	@property()
		accountType = 'trial';

	@query('input[id="email"]')
		_email: HTMLInputElement;

	@query('input[id="username"]')
		_username: HTMLInputElement;

	@query('#error-message')
		_error: HTMLSpanElement;

	_handleInput() {
		this.email = this._email.value;
		this.loginName = this._username.value;
	}

	async _registerUser(e: Event) {
		e.preventDefault();

		if(!this.email && this.email.includes("@")) {
			this.validEmail = false;
			this._error.innerHTML = "Alle Felder müssen ausgefüllt werden";
			return;
		}

		if(!this.loginName) {
			this.validLoginName = false;
			this._error.innerHTML = "Alle Felder müssen ausgefüllt werden";
			return;
		}

		try {
			await apiCreateTeacher(this.email, this.loginName, 'trial');

			showModal(`<h1>Vielen Dank für Ihre Registrierung!</h1><p>Wir haben eine E-Mail an <strong>${this.email}</strong> geschickt.<br/>Klicken Sie auf den Link in der E-Mail, um die Registrierung abzuschließen.</p>`, "centered-box", true);

		} catch (err) {
			console.error(err);
			let errorMessage = "";
			switch (err.errorCode) {
			case "emailInUse":
				errorMessage = "Die E-Mail-Adresse ist bereits vergeben.";
				break;
			case "loginNameInUse":
				errorMessage = "Das Kürzel ist bereits vergeben.";
				break;
			case "teacherAlreadyExists":
				errorMessage = "Die Lehrkraft existiert bereits.";
				break;
			default:
				errorMessage = "Die Registrierung war nicht erfolgreich, bitte versuchen Sie es erneut.";
			}
			this._error.innerHTML = errorMessage;
			this._error.classList.remove("hide");
		}
	}
	render() {
		return html`
			<form @submit=${this._registerUser}>
				<p>Hamburger Schulen bekommen einen 50% Rabatt auf das netBook und zahlen damit nur <b>30€ pro Klassenlizenz pro Jahr</b>.</p>
				<input id="email" type="email" placeholder="Email Adresse" value=${this.email} @input=${this._handleInput} data-valid=${this.validEmail}/>
				<input id="username" type="text" placeholder="Kürzel" value=${this.loginName} @input=${this._handleInput} data-valid=${this.validLoginName}/>
				<input type="submit" value="30 Tage kostenlos testen" />
				<span id="error-message" class="hide"></span>
			</form>
		`;
	}
}
